* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p {
  color: rgb(85, 85, 85);
}

a,
.btn {
  transition: all 0.3s ease;
  &:disabled {
    transition: none;
    pointer-events: none;
    text-decoration-line: line-through;
  }
}

nav,
.nav-links {
  display: flex;
}

nav {
  justify-content: space-around;
  align-items: center;
  height: 17vh;
}

.nav-links {
  gap: 2rem;
  list-style: none;
  font-size: 1.5rem;
}

a {
  color: black;
  text-decoration: none;
  text-decoration-color: white;
}

a:hover {
  color: gray;
  text-underline-offset: 1rem;
  text-decoration: underline;
  text-decoration-color: rgb(181, 181, 181);
}

.logo {
  font-size: 2rem;
}

.logo:hover {
  cursor: default;
}

#hamburger-nav {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 2px;
  background-color: black;
  transition: all 0.3 ease-in-out;
}

.menu-links {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.menu-links a {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
  transition: all 0.3 ease-in-out;
}

.menu-links li {
  list-style: none;
}

.menu-links.open {
  max-height: 300px;
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}
.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}
.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}

.hamburger-icon span:first-child {
  transform: none;
  opacity: 1;
}

section {
  padding-top: 4vh;
  min-height: 96vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;
}

.section-container,
#profile {
  display: flex;
}

#profile {
  justify-content: center;
  gap: 5rem;
  height: 80vh;
}

.section__pic-container {
  display: flex;
  height: 400px;
  width: 400px;
  margin: auto 0;
  .profile-pic {
    border-radius: 50%;
  }
}

.section__text {
  align-self: center;
  text-align: center;
}

.section__text p {
  font-weight: 600;
}

.section__text__p1 {
  font-size: 1.05rem;
  // display: none;
  text-align: center;
}

.section__text__p2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
  text-align: center;
}

#socials-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.icon {
  cursor: pointer;
  height: 2rem;
  &.round {
    background-color: white;
    border-radius: 50%;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 300ms ease;
  padding: 1rem;
  width: 8rem;
  border-radius: 1.5rem;
}

.btn-color-1,
.btn-color-2 {
  border: rgb(53, 53, 53) 0.1rem solid;
}

.btn-color-1:hover,
.btn-color-2:hover {
  cursor: pointer;
}

.btn-color-1,
.btn-color-2:hover {
  background: rgb(53, 53, 53);
  color: white;
}

.btn-color-1:hover {
  background: rgb(0, 0, 0);
}

.btn-color-2 {
  background: none;
}

.btn-color-2:hover {
  background: rgb(255, 255, 255) 0.1rem solid;
}

.btn-container {
  gap: 1rem;
}

#about {
  position: relative;
}

.about-containers {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.about-details-container {
  justify-content: center;
  flex-direction: column;
  display: flex;
  .text-container {
    hr {
      margin: 1rem 0;
    }
    .year {
      color: rgb(14, 23, 146);
    }
    .about-caption {
      font-weight: 500;
      font-size: 1.1rem;
      color: black;
      margin-bottom: 0.4rem;
      .year {
        font-weight: 400;
      }
    }
  }
  .details-container {
    display: flex;
    justify-content: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.details-container {
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  border-radius: 1.5rem;
  padding: 1.5rem;
  flex: 1;
  background: white;
  text-align: center;
  justify-self: center;
}

.about-pic {
  border-radius: 1.5rem;
}

.arrow {
  position: absolute;
  right: -5rem;
  bottom: 2.5rem;
}

.section-container {
  gap: 4rem;
  height: 80%;
}

.section__pic-container {
  height: 400px;
  width: 400px;
  margin: auto 0;
  &.banner-pic {
    margin: 0;
  }
}

#experience {
  position: relative;
}

.experience-sub-title {
  color: rgb(85, 85, 85);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.experience-details-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  min-height: 80%;
  &.pj {
    overflow-x: scroll;
  }
}

.article-container {
  display: flex;
  text-align: initial;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1.2rem;
  justify-content: baseline;
  &.projects {
    justify-content: space-around;
  }
}

.skills-container{
  margin-bottom:1rem;
  article {
    display: flex;
    min-width: 8.5rem;
    width: auto;
    justify-content:left;
    gap:0.2rem;
    div {
      p:first-child {
        font-size: 0.9rem;
        font-weight: 400;
      }
      p:nth-child(2) {
        font-weight: 100;
        font-size: 0.7rem;
      }
    }
  }
}

article {
  display: flex;
  width: 10rem;
  justify-content: space-around;
  gap: 0.5rem;
}

article .icon {
  cursor: default;
}

#projects {
  position: relative;
}

.color-container {
  display: flex;
  flex-direction: column;
  border-color: rgb(163, 163, 163);
  background: rgb(250, 250, 250);
  padding: 0;
}

.project-img {
  border-radius: 1.5rem 1.5rem 0 0;
  width: 100%;
  height: 175px;
  filter: drop-shadow(0 0 0.3rem rgb(163, 163, 163));
  object-fit: cover;
}

.project-buttons{
  margin-top: auto;
  padding:1rem;
}

.project-text {
  margin:auto;
  font-size: 0.8rem;
  padding: 1rem;
}

.project-title {
  margin: 1rem;
  margin-bottom:0;
  font-size: 1rem;
  color: black;
}

.project-btn {
  color: black;
  border-color: rgb(163, 163, 163);
}

#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  border-radius: 1.5rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  background: rgb(250, 250, 250);
  margin: 2rem auto;
  padding: 0.5rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
}

.contact-info-container p {
  font-size: larger;
}

.contact-icon {
  cursor: default;
}

.email-icon {
  height: 2.5rem;
}

footer {
  height: 26vh;
  margin: 0 1rem;
  p {
    text-align: center;
  }
}

.clamp-max {
  display: -webkit-box;
  max-width: 250px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
